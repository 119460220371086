<template>
    <v-container fluid>
  
      <v-row>        
        <v-col cols="12">
          
          <v-card dark outlined>
            
            <v-card-title class="secondary py-1 text-subtitle-1">
              <v-icon left dense> mdi-home </v-icon>
              Stallholders
              <v-spacer/>
            </v-card-title>

            <v-card-text class="pa-0">
              <v-data-table
                :headers="headers"
                :items="allStallHolders"
                single-select
                class="elevation-5"
                return-objects
              >
              <template v-slot:item.StallholderName="{ item }">
                  {{ item.StallholderName ? item.StallholderName : 'No data available' }}
              </template>
              <template v-slot:item.Abn="{ item }">
                  {{ item.Abn ? item.Abn : 'No data available' }}
              </template>
              <template v-slot:item.PaymentProfile="{ item }">
                  {{ item.PaymentProfile ? item.PaymentProfile.join(', ') : 'No data available' }}
              </template>
              <template v-slot:item.PrintingProfile="{ item }">
                  {{ item.PrintingProfile ? item.PrintingProfile : 'No data available' }}
              </template>
              <template v-slot:item.StandardDrinkLimit="{ item }">
                  {{ item.StandardDrinkLimit ? item.StandardDrinkLimit : 'No data available' }}
              </template>
              <template v-slot:item.StandardDrinkLimitEnforce="{ item }">
                  {{ item.StandardDrinkLimitEnforce ? item.StandardDrinkLimitEnforce : 'No data available' }}
              </template>
              <template v-slot:item.CardSurchargePercentage="{ item }">
                  {{ item.CardSurchargePercentage ? item.CardSurchargePercentage : 'No data available' }}
              </template>
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="800px"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left x-small>
                          mdi-plus
                        </v-icon>
                        Add StallHolder
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h6">
                        {{ formTitle }}
                      </v-card-title>
                      <v-divider></v-divider>
  
                      <v-card-text>
                        <v-container>
                         <v-form 
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >
                              
                            <label><B>Event Name:  </B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>
    
                            <v-text-field
                              v-model="editedItem.StallholderName"
                              label="StallHolder Name"
                              :rules="StallHolderNameRules"
                              required
                            ></v-text-field>

                            <v-row class="mb-2">
                              
                              <v-col cols="6">
                                <v-text-field
                                  v-model="editedItem.Abn"
                                  label="Abn"
                                  :rules="[v => !!v || 'Abn is required']"
                                  required
                                ></v-text-field>
                              </v-col>

                              <v-col cols="5" offset-md="1" style="margin-top: 45px; font-size: 18px;">
                                <span>Use event settings</span>
                              </v-col>

                            </v-row>

                            <v-row class="mb-2">

                              <v-col cols="6">
                                <label>Payment Profile</label>
                                <v-row class="ml-1">
                                    <v-checkbox
                                        v-model="editedItem.PaymentProfile"
                                        label="Cash"
                                        value="Cash"
                                        class="mr-5"
                                        :disabled="isSelectPaymentProfile"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="editedItem.PaymentProfile"
                                        label="Card"
                                        value="Card"
                                        class="mr-5"
                                        :disabled="isSelectPaymentProfile"
                                    ></v-checkbox>
                                    <v-checkbox
                                        v-model="editedItem.PaymentProfile"
                                        label="Voucher"
                                        value="Voucher"
                                        :disabled="isSelectPaymentProfile"
                                    ></v-checkbox>
                                </v-row>
                              </v-col>

                              <v-col cols="5" offset-md="1">
                                <v-checkbox
                                    v-model="isSelectPaymentProfile"
                                    :label="eventSettings.PaymentProfile"
                                    class="mr-5"
                                    style="padding-top: 12px;"
                                ></v-checkbox>
                              </v-col>

                            </v-row>
                                
                            <label>Printing Profile</label>
                            <v-row class="ml-1">

                              <v-col cols="6">
                                <v-row>
                                  <div v-for="item in PrintingList" :key="item.id">
                                    <v-checkbox
                                        v-model="editedItem.PrintingProfile"
                                        :label="item.name"
                                        :value="item.name"
                                        class="mr-5"
                                        :rules="eventPrintRules"
                                        required
                                        :disabled="isSelectPrintingProfile"
                                    ></v-checkbox>
                                  </div>
                                </v-row>
                              </v-col>

                              <v-col cols="5" offset-md="1">
                                <v-checkbox
                                    v-model="isSelectPrintingProfile"
                                    :label="eventSettings.PrintingProfile"
                                    class="mr-5"
                                    style="margin-top: 4px; margin-left: -8px"
                                ></v-checkbox>
                              </v-col>

                            </v-row>

                            <v-row>
                              <v-col cols="6">  
                                <v-combobox
                                    v-model="editedItem.StandardDrinkLimitEnforce"
                                    :items="EnforceTypes"
                                    item-text="name"
                                    label="Drink Limit Profile"
                                    :return-object="false"
                                    required
                                    :disabled="isSelectStandardDrinkLimitEnforce"
                                ></v-combobox>
                              </v-col>
                              <v-col cols="3" offset-md="1">
                                <v-checkbox
                                    v-model="isSelectStandardDrinkLimitEnforce"
                                    :label="eventSettings.StandardDrinkLimitEnforce?.toString() || ''"
                                    class="mr-5"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                            
                            <v-row>

                              <v-col cols="6">
                                <v-text-field
                                    v-model="editedItem.StandardDrinkLimit"
                                    label="Maximum Drink Limit"
                                    type="number"
                                    min="0"
                                    required
                                    :disabled="isSelectStandardDrinkLimit"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="3" offset-md="1">
                                <v-checkbox
                                    v-model="isSelectStandardDrinkLimit"
                                    :label="eventSettings.StandardDrinkLimit?.toString() || ''"
                                    class="mr-5"
                                ></v-checkbox>
                              </v-col>

                              <v-col cols="2">
                                <v-btn
                                  small
                                  dark
                                  style="margin-top: 25px;"
                                  @click="haddleScheduleModal()"
                                >
                                  <v-icon left x-small>
                                    mdi-calendar-clock
                                  </v-icon>
                                  Schedule
                                </v-btn>
                              </v-col>

                            </v-row>

                            <v-row>

                              <v-col cols="6">
                                <v-text-field
                                    v-model="editedItem.CardSurchargePercentage"
                                    label="Card Surcharge Percentage"
                                    type="number"
                                    min="0"
                                    required
                                    :disabled="isSelectCardSurchargePercentage"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="5" offset-md="1">
                                <v-checkbox
                                    v-model="isSelectCardSurchargePercentage"
                                    :label="eventSettings.CardSurchargePercentage?.toString() || ''"
                                    class="mr-5"
                                ></v-checkbox>
                              </v-col>
                            
                            </v-row>
                          
                         </v-form>
                        </v-container>
                      </v-card-text>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog dark v-model="dialogDelete" max-width="600px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete Stallholder ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogSchedule" max-width="600px" dark>
                    <v-card>
                      <v-card-title class="text-h6">
                        Standard Drink Schedule
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-container>

                          <v-row v-for="(item, index) in ScheduleList" :key="index">
                            <v-col cols="4">
                              <v-datetime-picker
                                  v-model="item.scheduleDate"
                                  :text-field-props="textFieldPropsStartDate"
                                  :date-picker-props="datePropsStartDate"
                                  :time-picker-props="timePropsStartDate"
                                  time-format="HH:mm"
                              ></v-datetime-picker>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                  v-model="item.Amount"
                                  label="Amount"
                                  type="number"
                                  min="0"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="mt-5">
                              <v-btn color="red lighten-2" x-small @click="removeRowSchedule(index)">Remove</v-btn> 
                                &nbsp;
                              <v-btn color="lighten-2" x-small @click="addRowSchedule()">Add</v-btn>
                            </v-col>
                          </v-row>

                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                >
                  No stallholders for this event
                </v-btn>
              </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
  import 'izitoast/dist/css/iziToast.min.css'; // loading css 
  import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'
  
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      dialogSchedule: false,

      headers: [
        { text: "Stallholder Name", value: "StallholderName"},
        { text: "Abn", value: "Abn"},
        { text: "Payment Profile", value: "PaymentProfile"},
        { text: "Printing Profile", value: "PrintingProfile"},
        { text: "Maximum Drink Limit", value: "StandardDrinkLimit"},
        { text: "Drink Limit Profile", value: "StandardDrinkLimitEnforce"},
        { text: "Card Surcharge (%)", value: "CardSurchargePercentage"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,

      PrintingList: [
          {
              id: 1,
              name: "Print"
          },
          {
              id: 2,
              name: "Prompt"
          },
          {
              id: 3,
              name: "Bypass"
          }
      ],

      EnforceTypes: [
          { id: true, name: 'Enforce'},
          { id: false, name: 'Prompt'}
      ],

      editedItem: {
        EventId: '',
        StallholderName: '',
        Abn: '',
        PaymentProfile: '',
        PrintingProfile: '',
        StandardDrinkLimit: '',
        StandardDrinkLimitEnforce: '',
        CardSurchargePercentage: '',
        PaymentProfileInherit: true,
        PrintingProfileInherit: true,
        StandardDrinkLimitInherit: true,
        StandardDrinkLimitEnforceInherit: true,
        CardSurchargePercentageInherit: true,
      },
      defaultItem: {
        EventId: '',
        StallholderName: '',
        Abn: '',
        PaymentProfile: '',
        PrintingProfile: '',
        StandardDrinkLimit: '',
        StandardDrinkLimitEnforce: '',
        CardSurchargePercentage: ''
      },
      Action: '',

      valid: true,
      StallHolderNameRules: [
        v => !!v || 'Stallholder Name is required',
      ],
      eventPrintRules: [
          v => !!v || 'Printing Profile is required',
      ],
      ActionList: ['create', 'update', 'delete'],

      eventSettings: {
        PaymentProfile: '',
        PrintingProfile: '',
        StandardDrinkLimitEnforce: '',
        StandardDrinkLimit: '',
        CardSurchargePercentage: ''
      },

      isSelectPaymentProfile: true,
      isSelectPrintingProfile: true,
      isSelectStandardDrinkLimitEnforce: true,
      isSelectStandardDrinkLimit: true,
      isSelectCardSurchargePercentage: true,

      // Schedule
      ScheduleList: [
        {
          scheduleDate: new Date(),
          Amount: ''
        }
      ],
      
      textFieldPropsStartDate: {
        appendIcon: 'event'
      },
      datePropsStartDate: {
        headerColor: 'black'
      },
      timePropsStartDate: {
        useSeconds: false,
        ampmInTitle: true
      }
    }),

    updated: function () {
      console.log(this.selectedEvent)
    },
  
    computed: {
      ...mapState('filter', ['globalEventFilter',
      // 'allEvents',
      'allStallHolders']),

      selectedEvent: {
        get() {
          return this.globalEventFilter
        },
        set (value) {
          console.log("EVENT IS CHANGED MAKE API REQUEST", value)
        }
      },

      formTitle () {
        return this.editedIndex === -1 ? 'New Stallholder' : 'Edit Stallholder'
      }
    },
  
    watch: {

      dialog (val) {
        val || this.close()
        if (val === true) {
          this.haddleEventSettings();
        }
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      isSelectPaymentProfile (val) {
          if (val !== null && val === true) {
          console.log('Yes', val)            
            this.editedItem.PaymentProfile = this.globalEventFilter.PaymentProfile
            this.eventSettings.PaymentProfile = this.globalEventFilter.PaymentProfile.join(', ')
            this.editedItem.PaymentProfileInherit = val;
          } else {
          console.log('No', val)
            this.editedItem.PaymentProfileInherit = false
          }
      },
      
      isSelectPrintingProfile (val) {
        if (val !== null && val === true) {
          this.editedItem.PrintingProfile = this.globalEventFilter.PrintingProfile;
          this.eventSettings.PrintingProfile = this.globalEventFilter.PrintingProfile;
          this.editedItem.PrintingProfileInherit = val;
        } else {
          this.editedItem.PrintingProfile = this.globalEventFilter.PrintingProfile;
          this.editedItem.PrintingProfileInherit = false
        }
      },
      isSelectStandardDrinkLimitEnforce (val) {
        console.log("isSelectStandardDrinkLimitEnforce",this.globalEventFilter)
        if (val !== null && val === true) {
          this.editedItem.StandardDrinkLimitEnforce = this.globalEventFilter.StandardDrinkLimitEnforce;
          this.eventSettings.StandardDrinkLimitEnforce = this.globalEventFilter.StandardDrinkLimitEnforce;
          this.editedItem.StandardDrinkLimitEnforceInherit = val;
        } else {
          this.editedItem.StandardDrinkLimitEnforce = this.globalEventFilter.StandardDrinkLimitEnforce;
          this.editedItem.StandardDrinkLimitEnforceInherit = false
      }
      },
      isSelectStandardDrinkLimit (val) {
        if (val !== null && val === true) {
          this.editedItem.StandardDrinkLimit =  this.eventSettings.StandardDrinkLimit;
          this.editedItem.StandardDrinkLimitInherit = val;
        } else {
          this.editedItem.StandardDrinkLimit = this.eventSettings.StandardDrinkLimit;
          this.editedItem.StandardDrinkLimitInherit = false
        }
      },
      isSelectCardSurchargePercentage (val) {
        if (val !== null && val === true) {
          this.editedItem.CardSurchargePercentage = this.eventSettings.CardSurchargePercentage;
          this.editedItem.CardSurchargePercentageInherit = val;
        } else {
          this.editedItem.CardSurchargePercentage = this.eventSettings.CardSurchargePercentage;
          this.editedItem.CardSurchargePercentageInherit = false
        }
      }
    },
  
    methods: {
      ...mapMutations('filter', ['setGlobalEventFilter',
      // 'setAllEvents',
      'setFilterStallHolders']),
      ...mapActions('admin', ['createEvent']),
      ...mapActions('filter', ['initialLogin']),
      
      haddleEventSettings: function () {
        // Runs on page load and closing the stallholder edit popup

        this.editedItem.PaymentProfile = this.globalEventFilter.PaymentProfile
        this.editedItem.PrintingProfile = this.globalEventFilter.PrintingProfile;
        this.editedItem.StandardDrinkLimit = this.globalEventFilter.StandardDrinkLimit;
        this.editedItem.CardSurchargePercentage = this.globalEventFilter.CardSurchargePercentage;
        this.editedItem.StandardDrinkLimitEnforce = this.globalEventFilter.StandardDrinkLimitEnforce === 'Enforce' ? 'Enforce' : 'Prompt';

      },

      editItem (item) {
        // Runs on stallholder edit popup

        this.editedIndex = this.allStallHolders.indexOf(item)

        this.editedItem.StallholderName = item.StallholderName;
        this.editedItem.StallholderId = item.StallholderId;
        this.editedItem.Abn = item.Abn;
        this.editedItem.StandardDrinkLimit = item.StandardDrinkLimit;
        this.editedItem.StandardDrinkLimitEnforce = item.StandardDrinkLimitEnforce;
        this.editedItem.PaymentProfile = item.PaymentProfile;
        this.editedItem.PrintingProfile = item.PrintingProfile;
        this.editedItem.CardSurchargePercentage = item.CardSurchargePercentage;        

        this.editedItem.PaymentProfileInherit = item.PaymentProfileInherit
        this.editedItem.PrintingProfileInherit = item.PrintingProfileInherit
        this.editedItem.CardSurchargePercentageInherit = item.CardSurchargePercentageInherit
        this.editedItem.StandardDrinkLimitInherit = item.StandardDrinkLimitInherit
        this.editedItem.StandardDrinkLimitEnforceInherit = item.StandardDrinkLimitEnforceInherit

        this.eventSettings.PaymentProfile = item.PaymentProfile !== '' ? item.PaymentProfile.join(', ') : '';
        this.eventSettings.PrintingProfile = item.PrintingProfile !== '' ? item.PrintingProfile : '';
        this.eventSettings.CardSurchargePercentage = item.CardSurchargePercentage !== '' ? item.CardSurchargePercentage : '';
        this.eventSettings.StandardDrinkLimit = item.StandardDrinkLimit !== '' ? item.StandardDrinkLimit : '';
        this.eventSettings.StandardDrinkLimitEnforce = item.StandardDrinkLimitEnforce !== '' ? item.StandardDrinkLimitEnforce : '';        

        this.isSelectPaymentProfile = item.PaymentProfileInherit === true ? true : false
        this.isSelectPrintingProfile = item.PrintingProfileInherit === true ? true : false
        this.isSelectCardSurchargePercentage = item.CardSurchargePercentageInherit === true ? true : false
        this.isSelectStandardDrinkLimit = item.StandardDrinkLimitInherit === true ? true : false
        this.isSelectStandardDrinkLimitEnforce = item.StandardDrinkLimitEnforceInherit === true ? true : false

        this.dialog = true
      },
  
      deleteItem (item) {
        this.editedIndex = this.allStallHolders.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
  
      deleteItemConfirm () {
        this.allStallHolders.splice(this.editedIndex, 1)
        
        try {
          if (this.editedItem.StallholderId) {
              let event_item = [
                  {
                      Type: "Stallholder",
                      Action: "delete",
                      Properties: this.editedItem
                  }
              ]
              
              let submitEvent = {
                  event_items: event_item
              }
              this.createEvent(submitEvent).then(response => {
                  console.log('iziToast response',response)

                  iziToast.success({
                      title: 'Stallholder',
                      message: 'Successfully deleted the record!',
                      position: 'topRight'
                  });

              }).catch(err => {
                  console.log("err")
                  console.log(err)

                  iziToast.warning({
                      title: 'Stallholder',
                      message: 'Fail to delete the record!',
                      position: 'topRight'
                  });
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.closeDelete()
      },
  
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        });
        // this.haddleIsSelecters();
      },
  
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      haddleScheduleModal: function () {
        this.dialogSchedule = true;
      },

      addRowSchedule () {
        let x = this.ScheduleList.length -1;
        if (this.ScheduleList[x].scheduleDate && this.ScheduleList[x].Amount) {
          this.ScheduleList.push(
            {
              scheduleDate: new Date(),
              Amount: ''
            }
          );
        }
      },

      removeRowSchedule (index) {
        this.ScheduleList.splice(index, 1);
      },
  
      save () {
        console.log("Starting save()")
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            Object.assign(this.allStallHolders[this.editedIndex], this.editedItem)
          } else {
            this.allStallHolders.push(this.editedItem)
          }

          try {
            if (this.$refs.form.validate()) {
                this.editedItem.EventId = this.selectedEvent.EventId;
                this.editedItem.StandardDrinkLimitEnforce = this.editedItem.StandardDrinkLimitEnforce === 'Enforce' ? 'Enforce' : 'Prompt';
                console.log("Sent event item: ",this.editedItem)
                let event_item = [
                    {
                        Type: "Stallholder",
                        Action: this.editedIndex === -1 ? 'create' : 'update',
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }

                this.createEvent(submitEvent).then(response => {
                    console.log(response)

                    iziToast.success({
                        title: 'Stallholder',
                        message: 'Successfully inserted record!',
                        position: 'topRight'
                    });

                    this.redirectReload();

                }).catch(err => {
                    console.log("err")
                    console.log(err)

                    iziToast.warning({
                        title: 'Stallholder',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
          this.close()
        }
      },
      redirectReload() {
        this.initialLogin().then(response => {
          console.log(response)
        })
      }
    }
  }
  </script>
  <style scoped>
  h1{
    color: #a09c94;
    font-family: sans-serif;
  }
  </style>